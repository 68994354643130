import { createApp } from 'vue'
import store from "@/store";
import router from "@/router";
import PrimeVue from "primevue/config"
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

//theme
import "primevue/resources/themes/md-light-indigo/theme.css";
import "primeflex/primeflex.min.css"
import "@mdi/font/css/materialdesignicons.min.css"

import App from './App.vue'

export const app = createApp(App).use(router).use(store)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .use(DialogService)
    .use(ToastService)
    .mount('#app')

export const dialogRef = app.$dialog;
export const toastRef = app.$toast;
