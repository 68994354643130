/**
 * File: api.ts
 * Language: TypeScript
 *
 * This file contains an object to fetch some data from an API.
 * The functions take an optional parameter 'params' as an object of query parameters.
 * It returns a Promise that resolves to an array of CompanyInterface objects or undefined.
 *
 * The function constructs a query string using the 'params' object and sends a GET request to the API endpoint.
 * The response is then mapped to an array of CompanyInterface objects and returned.
 * If an error occurs during the API request, it is logged to the console.
 */


// Summary Comment:
// Dies ist eine API-Klasse, die verschiedene Funktionen zum Abrufen, Erstellen, Aktualisieren und Löschen von Daten über eine API bereitstellt. Sie enthält Methoden für die Interaktion mit verschiedenen Ressourcen wie Unternehmen, Geräten, Dateien, Doctypes, Teilen und Protokollen. Die Klasse verwendet die Bibliothek "axios" für HTTP-Anfragen und "qs" für die Serialisierung von Abfrageparametern. Die Klasse exportiert eine einzige Instanz der API, die in anderen Teilen der Anwendung verwendet werden kann.

import API from './index'
import qs from 'qs'

import {
    CompanyInterface,
    DeviceInterface, DoctypeInterface, FileGenerateParams,
    FileInterface,
    LoginInterface, PartsInterface,
    UploadInterface, UserLoginInterface
} from "@/interfaces/form-interfaces";
import {Log} from '@/components/Logs/Log'
import {AxiosPromise, AxiosResponse} from "axios";
import {LogInterface, UploadPayloadData} from "@/interfaces/payload-interfaces";
import {
    ApiDataResponse, CompanieResponseBody,
    DeviceResponseBody, DoctypeResponseBody,
    LogResponseBody, MediaResponseBody,
    PartResponseBody, strapiResponse
} from "@/interfaces/api-interfaces";
import {Companie} from "@/components/Forms/companie";
import {Part} from "@/components/Forms/part";
import {Doctype} from "@/components/Forms/doctype";
import {File} from "@/components/Forms/file";
import {Device} from "@/components/Forms/device";


const api = {
    apiInstance: this,
    /**
     * Sets the Authorization header with the provided token.
     *
     * @param token - The token used for authentication.
     */
    setAuthHeader(token: string): void {
        if (token) {
            API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
    },
    /**
     * Entfernt den Autorisierungsheader aus den Standardkopfzeilen der API-Anfragen.
     */
    removeAuthHeader(): void {
        delete API.defaults.headers.common["Authorization"];
    },
    /**
     * API module for companies functionality.
     */
    companies: {
        // Function to get companies
        async get(params: Record<string, unknown> = {}): Promise<CompanyInterface[] | undefined> {
            // Generate query string
            const q = qs.stringify({...params, 'sort': 'id:DESC', populate: '*'})
            try {
                // Make API request to get companies
                const res: AxiosResponse<ApiDataResponse<CompanieResponseBody[]>> = await API.get(`/api/companies/?${q}`);
                // Map API response to CompanyInterface objects
                return res.data.data.map((c: CompanieResponseBody) => {
                    const comp: CompanyInterface = new Companie({
                        id: c.id,
                        name: c.attributes.name,
                        kdnr: c.attributes.kdnr,
                        desc: c.attributes.desc
                    })
                    return comp
                })
            } catch (err: unknown) {
                console.error(err)
            }
        },
        // Function to create a new company
        async post(data: CompanyInterface): Promise<CompanyInterface | false> {

            try {
                // Make API request to create a new company
                const res: AxiosResponse<ApiDataResponse<CompanieResponseBody>> =
                    await API.post("/api/companies", {data: data});
                if (res) {
                    // Return the created company as a CompanyInterface object
                    return new Companie({
                        id: res.data.data.id,
                        name: res.data.data.attributes.name,
                        kdnr: res.data.data.attributes.kdnr,
                        desc: res.data.data.attributes.desc
                    })
                } else {
                    return false
                }
            } catch (error) {
                console.log(error)
                throw error
            }

        },
        // Function to update an existing company
        async put(data: CompanyInterface): Promise<CompanyInterface | false> {
            // Make API request to update the company
            const res: AxiosResponse<ApiDataResponse<CompanieResponseBody>> =
                await API.put(`/api/companies/${data.id}`, {data: data});
            if (res.status === 200) {
                // Return the updated company as a CompanyInterface object
                return new Companie({
                    id: res.data.data.id,
                    name: res.data.data.attributes.name,
                    kdnr: res.data.data.attributes.kdnr,
                    desc: res.data.data.attributes.desc
                })
            } else {
                return false
            }
        },
        // Function to delete a company
        async delete(id: number): Promise<ApiDataResponse<any>> {
            try {
                // Make API request to delete the company
                const res = await API.delete('/api/companies/' + id)
                return res.data
            } catch (e: any) {
                throw e.response.data.error
            }

        }
    },
    /**
     * API module for file upload functionality.
     */
    upload: {
        // Function to retrieve uploaded data.
        async get(): Promise<ApiDataResponse<any>> {
            try {
                const res = await API.get("/api/upload");
                return res.data
            } catch (err: any) {
                throw new Error(err)
            }
        },
        // Function to upload data.
        async post(data: UploadPayloadData): Promise<any> {
            const res: AxiosResponse<[]> = await API.post("/api/upload", data);
            if (res.status === 200) {

                return res.data.map((upload: any) => {
                    const u: UploadInterface = {
                        id: upload.id,
                        name: upload.name,
                        url: upload.url
                    }

                    return u;
                });

            }
        },
        // Function to update uploaded data.
        put(data: UploadInterface): AxiosPromise<UploadInterface> {
            return API.put('/api/upload', data)
        },
        // Function to delete uploaded data.
        delete(id: string | undefined) {
           return API.delete('/api/upload/files/' + id)
        }
    },
    /**
     * API module for file management.
     */
    files: {
        /**
         * // Function to retrieve files.
         * // Params:
         * // - params: optional parameters for filtering files
         * // Returns: a promise that resolves to an array of FileInterface objects, or an empty array, or undefined
         * @param {Record<string | unknown>} params
         * @returns Promise<FileInterface[] | [] | undefined>
         */
        async get(params: Record<string, unknown> = {}): Promise<FileInterface[] | [] | undefined> {
            const q = qs.stringify({
                ...params,
                'sort': 'createdAt:DESC',
                'populate': '*',
                pagination: {start: 0, limit: 1000}
            })

            try {
                const res: strapiResponse<ApiDataResponse<MediaResponseBody[]>> = await API.get(`/api/medias/?${q}`)

                if (res.data) {
                    return res.data.data.map((f: MediaResponseBody) => {
                        return new File({
                            id: f.id,
                            filename: f.attributes.filename,
                            doctype: new Doctype({
                                id: f.attributes.doctype.data.id,
                                title: f.attributes.doctype.data.attributes.title,
                                desc: f.attributes.doctype.data.attributes.desc
                            }),
                            url: {
                                id: f.attributes.url.data.id,
                                url: f.attributes.url.data.attributes.url,
                            },
                            createdAt: f.attributes.createdAt
                        })
                    })
                } else {
                    return []
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * // Function to upload a file.
         * // Params:
         * // - file: the file to be uploaded
         * // Returns: a promise that resolves to the uploaded FileInterface object, or undefined
         * @param {FileInterface} file
         * @returns Promise<FileInterface | undefined>
         */
        async post(file: FileInterface): Promise<FileInterface | undefined> {
            try {
                const res: strapiResponse<ApiDataResponse<MediaResponseBody>> =
                    await API.post("/api/medias", {data: file}, {params: {populate: 'doctype, url'}});

                if (res.data) {
                    return new File({
                        id: res.data.data.id,
                        filename: res.data.data.attributes.filename,
                        createdAt: res.data.data.attributes.createdAt,
                        doctype: new Doctype({
                            id: res.data.data.attributes.doctype.data.id,
                            title: res.data.data.attributes.doctype.data.attributes.title,
                            desc: res.data.data.attributes.doctype.data.attributes.desc
                        }),
                        url: {
                            id: res.data.data.attributes.url.data.id,
                            url: res.data.data.attributes.url.data.attributes.url
                        },
                    })
                }

            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * // Function to update a file.
         * // Params:
         * // - file: the updated file
         * // Returns: an AxiosPromise with the updated FileInterface object
         *
         * @param {FileInterface} file
         * @returns AxiosPromise<FileInterface>
         */
        put(file: FileInterface): AxiosPromise<FileInterface> {
            return API.put('/api/medias', {data: file})
        },
        /**
         * / Params:
         * // - file: the file to be deleted
         * // Returns: a promise that resolves to the API response
         *
         * @param {FileInterface} file
         * @returns Promise<ApiDataResponse>
         */
        async delete(file: FileInterface): Promise<ApiDataResponse> {
            await api.upload.delete(file.url.id)
            return API.delete('/api/medias/' + file.id)
        },
        /**
         * // Function to generate files.
         * // Params:
         * // - sn: serial number
         * // - pass: password
         * // - docs: document information
         * // - locale: locale information
         * // Returns: a promise that resolves to an array of generated FileInterface objects, or undefined
         * @param {FileGenerateParams} {sn, pass, docs, locale}
         * @returns Promise<FileInterface[] | undefined>
         */
        async genarate({sn, pass, docs, locale}: FileGenerateParams): Promise<FileInterface[] | undefined> {
            console.log('in api', {sn, pass, docs, locale})
            return await API.post(`/api/documents/create-pdf`, {data: {sn, pass, docs, locale}});
        }
    },
    /**
     * This module contains functions for interacting with the devices API.
     * It includes functions for getting devices, getting a device by ID,
     * creating a new device, updating a device, and deleting a device.
     */
    devices: {
        /**
         * Get a list of devices.
         * @param params - Optional parameters for filtering and pagination.
         * @returns A promise that resolves to an array of DeviceInterface objects.
         */
        async get(params: Record<string, unknown> = {}): Promise<DeviceInterface[] | undefined> {
            const q = qs.stringify({
                ...params,
                populate: 'companie,part,media.url,media.doctype',
                sort: 'id:DESC',
                pagination: {start: 0, limit: 1000}
            });

            try {
                const res: strapiResponse<ApiDataResponse<DeviceResponseBody[]>> = await API.get(`/api/devices/?${q}`)
                if (res.data) {
                    return res.data.data.map((d: DeviceResponseBody) => {
                        return {
                            id: d.id,
                            sn: d.attributes.sn,
                            companie: {
                                id: d.attributes.companie.data?.id ?? '0',
                                name: d.attributes.companie.data?.attributes.name ?? '',
                                kdnr: d.attributes.companie.data?.attributes.kdnr ?? ''
                            },
                            part: {
                                id: d.attributes.part.data?.id,
                                title: d.attributes.part.data?.attributes.title,
                                desc: d.attributes.part.data?.attributes.desc,
                            },
                            files: d.attributes.media.data.map((f: MediaResponseBody) => {
                                return new File({
                                    id: f.id,
                                    filename: f.attributes.filename,
                                    doctype: new Doctype({
                                        id: f.attributes.doctype.data.id,
                                        title: f.attributes.doctype.data.attributes.title
                                    }),
                                    url: {id: f.attributes.url.data.id, url: f.attributes.url.data.attributes.url},
                                    createdAt: f.attributes.createdAt
                                })
                            })
                        }
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }

        },
        /**
         * Get a device by ID.
         * @param id - The ID of the device to retrieve.
         * @returns A promise that resolves to a DeviceInterface object.
         */
        async getById(id: string): Promise<DeviceInterface | undefined> {
            const q = qs.stringify({populate: 'companie,part,media.url,media.doctype'})

            try {
                const res: strapiResponse<ApiDataResponse<DeviceResponseBody>> = await API.get(`/api/devices/${id}?${q}`)

                if (res.data) {
                    const compRes = res.data.data.attributes.companie
                    const partRes = res.data.data.attributes.part
                    const mediaRes = res.data.data.attributes.media
                    const files = mediaRes.data.map((f: MediaResponseBody) => {
                        return new File({
                            id: f.id,
                            filename: f.attributes.filename,
                            url: {
                                id: f.attributes.url.data.id,
                                name: f.attributes.url.data.attributes.name,
                                url: f.attributes.url.data.attributes.url
                            },
                            doctype: new Doctype({
                                id: f.attributes.doctype.data.id,
                                title: f.attributes.doctype.data.attributes.title,
                                desc: f.attributes.doctype.data.attributes.desc
                            })
                        });
                    });
                    return new Device({
                        id: res.data.data.id,
                        sn: res.data.data.attributes.sn,
                        companie: new Companie({
                            id: compRes.data.id,
                            name: compRes.data.attributes.name,
                            kdnr: compRes.data.attributes.kdnr,
                            desc: compRes.data.attributes.desc
                        }),
                        part: new Part({
                            id: partRes.data.id,
                            title: partRes.data.attributes.title,
                            desc: partRes.data.attributes.desc
                        }),
                        files: files,
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Create a new device.
         * @param device - The device object to create.
         * @returns A promise that resolves to the created DeviceInterface object.
         */
        async post(device: DeviceInterface): Promise<DeviceInterface | undefined> {
            try {
                // Convert Objects to only Ids
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                device.part = [device.part.id];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                device.companie = [device.companie.id];
                // File Ids
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                device.media = device.files

                const res: strapiResponse<ApiDataResponse<DeviceResponseBody>> =
                    await API.post("/api/devices", {data: device}, {params: {populate: '*'}});

                if (res.data && res.data.data.id) {
                    const device = this.getById(res.data.data.id)
                    if (device) return device
                }
            } catch (err: any) {
                throw new Error(err)
            }

        },
        /**
         * Update an existing device.
         * @param device - The updated device object.
         * @returns A promise that resolves to the updated DeviceInterface object.
         */
        async put(device: DeviceInterface): Promise<DeviceInterface | undefined> {
            try {
                // Convert Objects to only Ids
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                device.part = [device.part.id];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                device.companie = [device.companie.id];
                // File Ids
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                device.media = device.files.map((file) => file.id)

                const res: AxiosResponse<ApiDataResponse<DeviceResponseBody>> =
                    await API.put(`/api/devices/${device.id}`, {data: device}, {params: {populate: '*'}})

                if (res.data && res.data.data.id) {
                    const device = this.getById(res.data.data.id)
                    if (device) return device
                }
            } catch (err: any) {
                throw new Error(err)
            }

        },
        /**
         * Delete a device by ID.
         * @param id - The ID of the device to delete.
         * @returns A promise that resolves to the deleted DeviceInterface object.
         */
        async delete(id: string): Promise<DeviceInterface> {
            try {
                const res: AxiosResponse<ApiDataResponse<DeviceResponseBody>> = await API.delete(`/api/devices/${id}`)
                return new Device({
                    id: res.data.data.id,
                    sn: res.data.data.attributes.sn,
                    companie: {name: '', kdnr: ''},
                    part: {title: ''},
                    files: []
                })
            } catch (err: any) {
                throw new Error(err)
            }
        }
    },
    /**
     * Module for interacting with the API to perform CRUD operations on Doctype resources.
     */
    doctypes: {
        /**
         * Retrieves a list of Doctype resources from the API.
         * @param {Record<string, unknown>} params - Optional query parameters.
         * @returns A Promise that resolves to an array of Doctype objects.
         */
        async get(params: Record<string, unknown> = {}): Promise<DoctypeInterface[] | undefined> {
            const q = qs.stringify({...params, 'sort': 'id:DESC', populate: '*'})

            try {
                const res: strapiResponse<ApiDataResponse<DoctypeResponseBody[]>> = await API.get(`/api/doctypes/?${q}`);

                return res.data.data.map((d: DoctypeResponseBody) => {
                    return new Doctype({id: d.id, title: d.attributes.title, desc: d.attributes.desc})
                })
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Creates a new Doctype resource via the API.
         * @param {DoctypeInterface} data - The Doctype object to create.
         * @returns A Promise that resolves to the newly created Doctype object.
         */
        async post(data: DoctypeInterface): Promise<DoctypeInterface | undefined> {
            const res: strapiResponse<ApiDataResponse<DoctypeResponseBody>> =
                await API.post("/api/doctypes", {data: data})

            if (res.data) {
                return new Doctype({
                    id: res.data.data.id,
                    title: res.data.data.attributes.title,
                    desc: res.data.data.attributes.desc
                })
            }
        },
        /**
         * Updates an existing Doctype resource via the API.
         * @param {DoctypeInterface} data - The updated Doctype object.
         * @returns A Promise that resolves to the updated Doctype object.
         */
        async put(data: DoctypeInterface): Promise<DoctypeInterface | undefined> {
            try {
                const res: strapiResponse<ApiDataResponse<DoctypeResponseBody>> =
                    await API.put('/api/doctypes', {data: data})

                if (res.data) {
                    return new Doctype({
                        id: res.data.data.id,
                        title: res.data.data.attributes.title,
                        desc: res.data.data.attributes.desc
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Deletes a Doctype resource via the API.
         * @param {number} id - The ID of the Doctype object to delete.
         * @returns A Promise that resolves to the deleted Doctype object.
         */
        async delete(id: number): Promise<DoctypeInterface | undefined> {
            try {
                const res = await API.delete('/api/doctypes' + id);
                if (res.data) {
                    return new Doctype({
                        id: res.data.data.id,
                        title: res.data.data.attributes.title,
                        desc: res.data.data.attributes.desc
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        }
    },
    /**
     * This module contains functions for retrieving, creating, updating, and deleting parts through an API.
     */
    parts: {
        /**
         * Retrieves parts through the API.
         * @param {Record<string, unknown>} params - Optional parameters for the query
         * @returns A Promise that resolves to an array of parts or undefined
         * @throws An error if an error occurs during the API request
         */
        async get(params: Record<string, unknown> = {}): Promise<PartsInterface[] | undefined> {
            const q = qs.stringify({...params, 'sort': 'id:DESC', populate: '*'})

            try {
                const res: strapiResponse<ApiDataResponse<PartResponseBody[]>> = await API.get(`/api/parts/?${q}`)

                if (res.data) {
                    return res.data.data.map((p: PartResponseBody) => {
                        return new Part({id: p.id, title: p.attributes.title, desc: p.attributes.desc})
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Creates a new part through the API.
         * @param {PartsInterface} data - Data for the new part
         * @returns A Promise that resolves to the new part or undefined
         * @throws An error if an error occurs during the | undefined> {
         */
        async post(data: PartsInterface): Promise<PartsInterface | undefined> {
            try {
                const res: strapiResponse<ApiDataResponse<PartResponseBody>> =
                    await API.post("/api/parts", {data: data});

                if (res.data) {
                    return new Part({
                        id: res.data.data.id,
                        title: res.data.data.attributes.title,
                        desc: res.data.data.attributes.desc
                    })
                }
            } catch (err: any) {
                throw err.response.data.error
            }
        },
        /**
         * Updates an existing part through the API.
         * @param {PartsInterface} data - Updated data for the part
         * @returns A Promise that resolves to the updated part or undefined
         * @throws An (PartsInterface): Promise<PartsInterface | undefined> {
         */
        async put(data: PartsInterface): Promise<PartsInterface | undefined> {
            try {
                const res: strapiResponse<ApiDataResponse<PartResponseBody>> = await API.put(`/api/parts/${data.id}`, {data: data})
                if (res.data) {
                    return new Part({
                        id: res.data.data.id,
                        title: res.data.data.attributes.title,
                        desc: res.data.data.attributes.desc
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Deletes a part through the API.
         * @param {number} id - ID of the part to delete
         * @returns A Promise that resolves to the deleted part or undefined
         * @throws An error if an error occurs during the API request
         */
        async delete(id: number): Promise<PartsInterface | undefined> {
            try {
                const res = await API.delete('/api/parts/' + id)

                if (res.data) {
                    return new Part({id: res.data.data.id, title: res.data.data.attributes.title})
                }
            } catch (err: any) {
                throw new Error(err)
            }
        }
    },
    logs: {
        /**
         * Ruft Fehler-Logs ab.
         * @param {Record<string, unknown>} params - Optionale Parameter für die Abfrage.
         * @returns {Promise<LogInterface[] | undefined>} Eine Promise, die ein Array von Log-Objekten oder undefined zurückgibt.
         */
        async getErrorLogs(params: Record<string, unknown> = {}): Promise<LogInterface[] | undefined> {
            const q = qs.stringify({
                ...params,
                filters: {type: {$eq: 'error'}},
                sort: {id: 'DESC'},
                pagination: {start: 0, limit: 1000},
                populate: '*'
            });

            try {
                const res: strapiResponse<ApiDataResponse<LogResponseBody[]>> = await
                    API.get(`/api/logs/?${q}`,)

                if (res.data) {
                    return res.data.data.map((l: any) => {
                        return new Log({
                            id: l.id,
                            status: l.attributes.status,
                            type: l.attributes.type,
                            message: l.attributes.message,
                            user: l.attributes.user,
                            sn: l.attributes.sn,
                            companie: l.attributes.companie ?? null,
                            createdAt: l.attributes.createdAt
                        })
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Ruft Usage-Logs ab.
         * @param {Record<string, unknown>} params - Optionale Parameter für die Abfrage.
         * @returns {Promise<LogInterface[] | undefined>} Eine Promise, die ein Array von Log-Objekten oder undefined zurückgibt.
         */
        async getUsageLogs(params: Record<string, unknown> = {}): Promise<LogInterface[] | undefined> {
            const q = qs.stringify({
                ...params,
                filters: {'type': {$eq: 'info'}},
                sort: 'id:DESC',
                pagination: {start: 0, limit: 1000},
                populate: '*'
            });

            try {
                const res: strapiResponse<ApiDataResponse<LogResponseBody[]>> = await
                    API.get(`/api/logs/?${q}`)

                if (res.data) {
                    return res.data.data.map((l: any) => {
                        return new Log({
                            id: l.id,
                            status: l.attributes.status,
                            type: l.attributes.type,
                            message: l.attributes.message,
                            sn: l.attributes.sn,
                            user: l.attributes.user,
                            companie: l.attributes.companie ?? null,
                            createdAt: l.attributes.createdAt
                        })
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }
        },
        /**
         * Ruft Kunden-Logs ab.
         * @param {Record<string, unknown>} params - Optionale Parameter für die Abfrage.
         * @returns {Promise<LogInterface[] | undefined>} - Eine Promise, die ein Array von Log-Objekten oder undefined zurückgibt.
         */
        async getCustomerLogs(params: Record<string, unknown> = {}): Promise<LogInterface[] | undefined> {
            const q = qs.stringify({
                ...params,
                filters: {'type': {$eq: 'customer'}},
                sort: 'id:DESC',
                pagination: {start: 0, limit: 1000},
                populate: '*'
            });

            try {
                const res: strapiResponse<ApiDataResponse<LogResponseBody[]>> = await
                    API.get(`/api/logs/?${q}`)

                if (res.data) {
                    return res.data.data.map((l: any) => {
                        return new Log({
                            id: l.id,
                            status: l.attributes.status,
                            type: l.attributes.type,
                            message: l.attributes.message,
                            sn: l.attributes.sn,
                            user: l.attributes.user,
                            companie: l.attributes.companie ?? null,
                            createdAt: l.attributes.createdAt
                        })
                    })
                }
            } catch (err: any) {
                throw new Error(err)
            }

        },
        /**
         * Erstellt einen neuen Log-Eintrag.
         * @param {LogInterface} log - Das Log-Objekt, das erstellt werden soll.
         * @returns {Promise<LogInterface | undefined>} - Eine Promise, die das erstellte Log-Objekt oder undefined zurückgibt.
         */
        async post(log: LogInterface): Promise<LogInterface | undefined> {
            try {
                // Convert
                const apiData: ApiDataResponse<any> = {
                    data: {
                        type: log.type,
                        status: log.status,
                        message: log.message,
                        sn: log.sn ?? '',
                        companie: log.companie ?? null,
                        user: log.user ?? null
                    }

                }
                const res: strapiResponse<ApiDataResponse> = await API.post('/api/logs', apiData)
                if (res.data) {
                    return new Log({
                        status: res.status.toString(),
                        type: res.data.data.type,
                        message: res.data.data.message
                    })
                }
            } catch (err: any) {
                throw err.response.data.error
            }
        },
        /**
         * Löscht einen Log-Eintrag anhand seiner ID.
         * @param {string | number} id - Die ID des zu löschenden Log-Eintrags.
         * @returns Die gelöschten Daten des Log-Eintrags.
         */
        async delete(id: string | number) {
            try {
                const res: strapiResponse<ApiDataResponse> = await API.delete('/api/logs/' + id)
                return res.data.data
            } catch (err: any) {
                throw err.response.data.error
            }
        }
    },
    /**
     * Funktion zum Anmelden des Geräts.
     * @param {LoginInterface} params - Die Anmeldedaten des Geräts.
     * @returns {Promise<DeviceInterface[] | undefined>} Eine Liste von Geräteobjekten oder undefined.
     * @throws Fehlermeldung im Falle eines Fehlers.
     */
    async deviceLogin(params: LoginInterface): Promise<DeviceInterface[] | undefined> {
        try {
            const res: strapiResponse<ApiDataResponse<DeviceResponseBody[]>> = await API.post("/api/devices/customer-login", params);

            if (res.data) {
                const devRes: DeviceInterface[] = res.data.data.map((d: any) => {
                    const device = new Device({
                        id: d.id,
                        sn: d.sn,
                        part: new Part({
                            id: d.part.id,
                            title: d.part.title,
                            desc: d.part.desc
                        }),
                        companie: new Companie({
                            id: d.companie.id,
                            name: d.companie.name,
                            kdnr: d.companie.kdnr,
                            desc: d.companie.desc
                        }),
                        files: d.media.map((f: any) => {
                            return new File({
                                id: f.id,
                                filename: f.filename,
                                doctype: new Doctype({
                                    id: f.doctype.id,
                                    title: f.doctype.title
                                }),
                                url: {
                                    id: f.url.id,
                                    url: f.url.url
                                },
                                createdAt: f.createdAt
                            })
                        })
                    })

                    return device
                })

                return devRes
            }

        } catch (err: any) {
            throw err.response.data.error
        }
    },
    /**
     * Sends a user login request to the server using the provided parameters.
     * @param {UserLoginInterface} params - The user login parameters.
     * @returns A promise that resolves to the response from the server.
     */
    userLogin(params: UserLoginInterface): AxiosPromise<any> {
        return API.post("/api/auth/local", params);
    }
};

export default api;
