
import {defineComponent} from "vue";

import Button from "primevue/button";

export default defineComponent({
  name: 'CopyBtn',
  components: {Button},
  props: {
    color: {
      type: String,
      required: false,
      default: 'secondary'
    }
  },
  data() {
    return {};
  },
});
