//make this imports dynamic, when they needed

import {defineAsyncComponent, shallowRef} from "vue";
import CompanyForm from "@/components/Forms/CompanyForm.vue";

import {
    CompanyInterface,
    DeviceInterface,
    DoctypeInterface,
    FileInterface,
    PartsInterface, UserDataInterface
} from "@/interfaces/form-interfaces";
import transformer from "babel-jest";

export class State {
    authKey = "";
    currentUser: UserDataInterface | null = null;
    isAuthenticated = false;
    uploadStatus = false;
    companies: CompanyInterface[] = [];
    devices: DeviceInterface[] = [];
    files: FileInterface[] = [];
    doctypes: DoctypeInterface[] = [];
    parts: PartsInterface[] = [];
    currentDevice: DeviceInterface | any = {};
    isModalDialog = false;
    modals: any = {
        CompanyForm: {
            component: shallowRef(CompanyForm),
            attrs: {},
            props: {
                name: "CompanyForm",
                contentClass: "company-form",
                style: {
                    height: "auto",
                    width: "60%",
                }
            }
        },
        PartsForm: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/Forms/PartsForm.vue'))),
            attrs: {},
            props: {
                name: "PartsForm",
                contentClass: "parts-form",
                style: {
                    height: "auto",
                    width: "60vw",
                },
            }
        },
        DoctypesForm: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/Forms/DoctypesForm.vue'))),
            attrs: {},
            props: {
                name: "DoctypesForm",
                contentClass: "doctype-form", style: {
                    height: "auto",
                    width: "60vw",
                }
            }
        },
        FileForm: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/Forms/FileForm.vue'))),
            attrs: {},
            props: {
                name: "FileForm",
                contentClass: "file-form",
                modal: true,
                style: {
                    width: '50vw'
                }
            }
        },
        DeviceForm: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/Forms/DeviceForm.vue'))),
            attrs: {},
            props: {
                name: "DeviceForm",
                header: 'Devices',
                contentClass: "DeviceForm",
                modal: true,
                style: {
                    width: '70vw',
                    height: 'auto',
                },
                height: "auto",
                width: "95%",
            }
        },
        QrCode: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/Devices/QrCode.vue'))),
            attrs: {},
            props: {
                name: "QrCode",
                contentClass: "QrCode",
                header: 'DRUCK-LABEL',
                modal: true
            }
        },
        Loader: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/UI/Loader.vue'))),
            props: {
                name: "Loader",
                showHeader: false,
                style: {
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignContent: 'center',
                    backgroundColor: 'transparent'
                },
                contentStyle: {
                    backgroundColor: 'transparent'
                },
                contentClass: 'loader',
                breakpoints: {
                    '960px': '75vw',
                    '640px': '90vw'
                },
                modal: true,
                closable: false
            }
        },
        MessageBox: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/UI/MessageBox.vue'))),
            attrs: {
                type: "success",
                message: "Hi, i`m the fallback Message"
            },
            props: {
                name: "MessageBox",
                class: "message-box",
                height: "auto",
                width: "50%"
            }
        },
        FilesModal: {
            component: shallowRef(defineAsyncComponent(() => import('@/components/Files/FileShell.vue'))),
            props: {
                name: 'FilesModal',
                height: "auto",
                width: "95%",
                scrollable: true
            }
        }
    }
}
