
import {defineComponent} from "vue";
import Button from "primevue/button";

export default defineComponent({
  name: 'CloseBtn',
  components: {
    Button
  },
  data: function () {
    return {};
  },
  methods: {
    onClose(): void {
      this.$emit('click');
    },
  },
});
