import {PartsInterface} from "@/interfaces/form-interfaces";

export class Part {
    id?: string;
    title: string;
    desc?: string;

    constructor(props: PartsInterface) {
        this.id = props.id;
        this.title = props.title;
        this.desc = props.desc;
    }

}
