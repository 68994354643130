
import {defineComponent} from "vue";

import Button from "primevue/button";

export default defineComponent( {
  name: "QrBtn",
  components: {Button},
  data() {
    return {};
  },
});
