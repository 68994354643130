
import {defineComponent} from "vue";
import {mapActions} from 'vuex';

import LoginForm from '@/components/Forms/LoginForm.vue';
import {LoginInterface} from "@/interfaces/form-interfaces";

export default defineComponent({
  name: 'CustomerLogin',
  components: {
    LoginForm,
  },
  data() {
    return {};
  },
  computed: {
    snQuery() {
      if (this.$route.query.sn) {
        return this.$route.query.sn;
      }

      return '';
    },
  },
  methods: {
    ...mapActions(['loginCustomer']),
    async onSubmitForm(eventdata: LoginInterface) {
      // console.log(JSON.stringify(eventdata));
      try {
        const res = await this.loginCustomer({
          sn: eventdata.username,
          pass: eventdata.pass,
        });

        if (res) this.$router.push('customer-view');
      } catch (e) {
        //
      }
    },
    changePass(user: string) {
      console.log(user)
    }
  },
});
