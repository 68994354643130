import {LogInterface} from "@/interfaces/payload-interfaces";
import {CompanyInterface, UserDataInterface} from "@/interfaces/form-interfaces";

export class Log {
    id: string;
    status: string | number;
    type: string;
    message: string;
    sn: string;
    user: UserDataInterface | string | null;
    companie: CompanyInterface | string | null;
    createdAt: string

    constructor({
                    id = '',
                    status,
                    type,
                    message,
                    sn = '',
                    user = null,
                    companie = null,
                    createdAt = ''
                }: LogInterface) {
        this.id = id;
        this.status = status;
        this.type = type;
        this.message = message;
        this.sn = sn;
        this.user = user;
        this.companie = companie;
        this.createdAt = createdAt;
    }
}
