import {CompanyInterface} from "@/interfaces/form-interfaces";

export class Companie {
    id?: string;
    name: string;
    kdnr: string;
    pass?: string;
    desc?: string;

    constructor(props: CompanyInterface) {
        this.id = props.id;
        this.name = props.name;
        this.kdnr = props.kdnr;
        this.pass = props.pass;
        this.desc = props.desc;
    }
}
