import {State} from "@/store/state";
import {GetterTree} from "vuex";

export const getters: GetterTree<State, any> = {
    authKey: (state: State) => state.authKey,
    currentUser: (state: State) => state.currentUser,
    isAuthenticated: (state: State) => state.isAuthenticated,
    uploadStatus: (state: State) => state.uploadStatus,
    companies: (state: State) => state.companies,
    devices: (state: State) => state.devices,
    files: (state: State) => state.files,
    doctypes: (state: State) => state.doctypes,
    parts: (state: State) => state.parts,
    currentDevice: (state: State) => state.currentDevice,
    isModalOpen: (state: State) => state.isModalDialog,
    modals: (state: State) => state.modals
};
