import {DeviceInterface, DoctypeInterface, FileInterface, UploadInterface} from "@/interfaces/form-interfaces";

/**
 * Represents a file.
 */
export class File {
    id?: string
    filename: string
    devices?: Array<DeviceInterface>
    doctype: DoctypeInterface
    url: UploadInterface
    createdAt?: string

    /**
     * Creates a new instance of the File class.
     * @param props - The properties of the file.
     */
    constructor(props: FileInterface) {
        this.id = props.id
        this.filename = props.filename
        this.devices = props.devices
        this.doctype = props.doctype
        this.url = props.url
        this.createdAt = props.createdAt
    }
}
