import {createStore, StoreOptions} from "vuex";

import {State} from "./state"
import {getters} from "@/store/getters";
import {mutations} from "@/store/mutations";
import {actions} from "@/store/actions";
export const modules = {};

/**
 * This code initializes a store using the createStore function from a library.
 * The store is configured with various options such as state, getters, mutations, actions, and modules.
 * The state is set to a new instance of the State class.
 * The store is created using the provided options.
 */
const store: any = createStore(<StoreOptions<any>>{
    state: new State(),
    getters,
    mutations,
    actions,
    modules
});

/**
 * @description Export of VUEX Store Instance
 */
export default store;
