
import {defineComponent, shallowRef} from "vue";
import {mapGetters, mapActions} from 'vuex';

import {FilterMatchMode} from "primevue/api";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Loader from "@/components/UI/Loader.vue";

//import DeviceItem from '@/components/Devices/DeviceItem'
import {EventBus, emitter} from '@/store/eventBus';
import QrBtn from '@/components/UI/QrBtn.vue';
import EditBtn from '@/components/UI/EditBtn.vue';
import DeleteBtn from '@/components/UI/DeleteBtn.vue';
import CopyBtn from '@/components/UI/CopyBtn.vue';
import FileList from '@/components/Files/FileList.vue';

/**
 * @description DeviceList - is the initial component used in the App.vue
 * to render the devices as list from DB / Api Endpoint
 *
 * @vue-prop {Array} [items=[]] - a list of device items passed to the component
 * @vue-prop {boolean} [isAdmin=false] - a prop to indicate if used in admin context, default is false
 *
 * @vue-data {object} [filters={global: {value: null, matchMode: FilterMatchMode.CONTAINS}}] - use default global matchMode "CONTAINS" for primevue dropdown filter
 * @vue-data {Array} [expanded=[]] - a list of elements to expand in the DataTable
 * @vue-data {string} [selectedDevice=''] - vModel for the search/select field
 * @vue-data {array} [deviceResults=[]] - array to include in the DataTable Component
 * @vue-data {string} [modalName='DeviceForm'] - allows to set dynamic a component to the modal view,
 * awailable modal objects will be found under Global -> state {@link global#state}
 *
 *
 * @vue-computed {Array<DeviceItem>} devices - this property will be loaded via mapGetters()-Method
 * from the VUEX state if the component is mounted
 *
 *
 */
export default defineComponent({
      name: 'DeviceList',
      props: {
        items: {type: Array, default: () => []},
        isAdmin: {type: Boolean, default: false},
      },
      components: {
        Badge,
        Button,
        Card,
        Column,
        DataTable,
        InputText,
        FileList,
        QrBtn,
        EditBtn,
        DeleteBtn,
        CopyBtn,
      },
      data: () => ({
        filters: {
          global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        },
        expanded: [],
        selectedDevice: '',
        deviceResults: [],
        modalName: 'DeviceForm',
      }),
      computed: {
        ...mapGetters(['devices', 'currentDevice']),
      },
      methods: {
        ...mapActions([
          'showModal',
          'hideModal',
          'hideAllModals',
          'setCurrentDevice',
          'removeDevice',
        ]),
        /**
         * @method addNew
         * @description set the currentDeviceID to null and the modalName to DeviceForm and show Modal Component
         * @returns {void}
         */
        addNew() {
          this.setCurrentDevice(null);
          this.showModal({
            name: 'DeviceForm',
            componentProps: {
              mode: 'new',
            },
          });
        },
        /**
         * @method genQr
         * @description set the modalName to QrCode and show the Modal Component
         * @param {SetCurrentDevicePayload} id the ID of the selected Device
         * @returns {ModalComponent}
         */
        genQr(id: number | string) {
          this.setCurrentDevice({deviceId: id});
          this.showModal({name: 'QrCode'});
        },
        /**
         * @method editDevice
         * @description set the currentDevice to given id, sets modalName to QrCode and show the Modal Component
         * @param {SetCurrentDevicePayload} id the ID of the selected Device
         * @returns {ModalComponent}
         */
        editDevice(id: number | string) {
          this.setCurrentDevice({deviceId: id});
          this.showModal({
            name: 'DeviceForm',
            componentProps: {
              mode: 'update',
            },
          });
        },
        /**
         * @method copyDevice
         * @description The copyDevice method copies a device from the devices array and sets it as the current device.
         * The method then calls the showModal method with the mode set to new.
         *
         * @param {string} id
         * @return {void}
         */
        copyDevice(id: number | string) {
          // copy device
          const device = {...this.devices.find((dev:any) => dev.id === id)}
          // delete keys
          delete device.id;
          delete device._id;
          delete device.pass;
          // set current device
          this.setCurrentDevice(device);
          // call modal with mode new
          this.showModal({
            name: 'DeviceForm',
            componentProps: {
              mode: 'new',
            },
          });
        },
        /**
         * @method deleteDevice
         * @description remove device of given id from the devices array in the Store
         * @returns {boolean}
         * @param device
         */
        async deleteDevice(device: any) {
          // api call to delete the device
          if (confirm('Want you realy to delete?')) {
            await this.removeDevice(device.id);
          }
        },
      },
      watch: {
        selectedDevice(val:any) {
          const devId = this.devices.filter(
              (device:any) => device.sn === val || device.companie.name.includes(val)
          );
          this.deviceResults = devId;
          //this.setCurrentDevice({deviceId: devId[0]['id']})
        },
      },
      created() {
        //this.showModal({name: 'Loader'});

        // eventlistening to delete File
        emitter.on('delete-clicked-onFile', this.deleteDevice);
        /*// only if the currentDevice not empty copy to formData
        if (this.currentDevice && this.currentDevice.sn) {
          this.formData = {...this.currentDevice};
        }
        // otherwise copy the initFormData
        else {
          this.formData = {...this.initFormData, pass: ''};
        }*/
      },
      unmounted() {
        emitter.off('delete-clicked-onFile');
      },
    }
)
