/**
 * eventBus.ts
 *
 * Dieses Modul definiert den EventBus, der es ermöglicht, Ereignisse zwischen verschiedenen Komponenten zu übertragen.
 */

import mitt from "mitt";
import {CompanyInterface, FileInterface} from "@/interfaces/form-interfaces";
import {deleteInterface} from "@/interfaces/payload-interfaces";

// Erstellt eine Instanz des Event Emitters
export const emitter = mitt();

/**
 * EventBus
 *
 * Eine Sammlung von Funktionen, die verwendet werden, um Ereignisse über den EventBus zu senden.
 */
export const EventBus = {
  /**
   * onDeleteBtn
   *
   * Sendet das Ereignis 'delBtnClicked' mit dem angegebenen Wert.
   *
   * @param val Der Wert, der mit dem Ereignis gesendet werden soll. Standardwert ist null.
   */
  onDeleteBtn(val: deleteInterface | any = null) {
    emitter.emit('delBtnClicked', val);
  },

  /**
   * onSelectFiles
   *
   * Sendet das Ereignis 'filesSelected' mit der angegebenen Dateiauswahl.
   *
   * @param {FileInterface[]} val Die ausgewählten Dateien, die mit dem Ereignis gesendet werden sollen. Standardwert ist ein leeres Array.
   */
  onSelectFiles(val: FileInterface[] = []) {
    emitter.emit('filesSelected', val);
  },

  /**
   * onSelectCompanies
   *
   * Sendet das Ereignis 'companiesSelected' mit der angegebenen Unternehmensauswahl.
   *
   * @param {CompanyInterface[]} val Die ausgewählten Unternehmen, die mit dem Ereignis gesendet werden sollen. Standardwert ist ein leeres Array.
   */
  onSelectCompanies(val: CompanyInterface[] = []) {
    emitter.emit('companiesSelected', val);
  }
};
