
import {defineComponent} from "vue";
import Message from 'primevue/message';

export default defineComponent({
  name: "MessageBox",
  components: {Message},
  props: {
    type: {
      type: String,
      default: 'error'
    },
    message: {
      type: String,
      default: ''
    }
  },
});
