
import {defineComponent} from "vue"
import Toast from "primevue/toast";
import DynamicDialog from "primevue/dynamicdialog";

export default defineComponent({
  components: {
    Toast,
    DynamicDialog
  }
})
