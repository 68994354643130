
import {defineComponent} from "vue";
import {mapGetters, mapActions} from 'vuex';
import DeviceList from "@/components/Devices/DeviceList.vue";

export default defineComponent({
  name: 'CustomerView',
  components: {
    DeviceList,
  },
  computed: {
    ...mapGetters(['currentDevice']),
  },
  methods: {
    ...mapActions(['hideAllModals', 'setCurrentDevice'])
  },
  async mounted() {
    await this.currentDevice;

    this.hideAllModals();
  }
});
