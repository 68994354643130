
import {defineComponent, PropType} from "vue";
import {Computed, mapActions} from 'vuex';

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";

import CloseBtn from '../UI/CloseBtn.vue';
import MessageBox from '@/components/UI/MessageBox.vue';
import {Companie} from "@/components/Forms/companie";
import {CompanyInterface} from "@/interfaces/form-interfaces";

export default defineComponent({
  name: 'CompanyForm',
  inject: ['dialogRef'],
  components: {
    Button,
    InputNumber,
    InputText,
    Textarea,
    MessageBox,
    CloseBtn,
  },
  props: {
    mode: {
      type: String,
      default: 'new',
    },
    item: {
      type: Object as PropType<CompanyInterface>,
      required: false
    }
  },
  data() {
    return ({
      id: this.item?.id ?? '0',
      name: this.item?.name ?? '',
      kdnr: <string>this.item?.kdnr ?? '',
      pass: <string>this.item?.pass ?? '',
      desc: <string>this.item?.desc ?? '',
      formMode: <string>this.mode,
      messages: <string[]>[],
    });
  },
  computed: {
    isValidForm(): boolean {
      return this.name.length !== 0 || this.kdnr.length !== 0 || this.pass.length !== 0
    },
  },
  methods: {
    ...mapActions(['showModal', 'hideModal', 'newCompanie', 'updateCompanie', 'genPass']),
    genarate(): void {
      this.genPass(this.kdnr)
          .then((result) => {
            this.pass = result
          })
          .catch((err) => {
            console.log(err)
          })
    },
    toClipboard(text: string) {
      const vm = this;
      window.navigator.clipboard.writeText(text).then(
          function () {
            /* clipboard successfully set */
            vm.$toast.add({severity: 'success', detail: 'Content copied!', life: 3000})
          },
          function () {
            /* clipboard write failed */
            vm.$toast.add({severity: 'error', detail: 'Copy failed!' , life: 3000})
          },
      );
    },
    validateInputs(): boolean {
      // reset the messages
      this.messages = [];
      // validate name
      if (this.name.length === 0) {
        this.messages.push(`Bitte Firmennamen angeben!`)
      }
      // validate kdnr
      if (this.kdnr.length === 0) {
        this.messages.push(`Kundennummer darf nicht leer sein!`)
      }
      // validate pass
      if (this.formMode === 'new' && this.pass.length === 0) {
        this.messages.push(`Passwort darf nicht leer sein!`)
      }
      // return falsy value because messages exists
      // otherwise return true
      return !this.messages.length;
    },
    async sendForm(): Promise<any> {
      if (!this.validateInputs()) {
        this.$toast.add({severity: 'error', detail: 'Validierungsfehler!', life: 3000})
        // the messages will be shown from template -> 54
      } else {
        if (this.formMode === 'new') {
          const loaderDialog = await this.showModal({name: 'Loader'})
          // Send the Data on Api/Store
          await this.newCompanie(new Companie({
            name: this.name,
            kdnr: this.kdnr,
            pass: this.pass.toString(),
            desc: this.desc,
          }));
          // close the Modals
          loaderDialog.close();
          this.closeDialog();
        }
        if (this.formMode === 'update') {
          const loaderDialog = await this.showModal({name: 'Loader'})
          // Send the Data on Api/Store
          const newCompanie = new Companie({
            id: this.id,
            name: this.name,
            kdnr: this.kdnr,
            desc: this.desc,
          })
          if (this.pass) {
            newCompanie.pass = this.pass.toString()
          }
          await this.updateCompanie(newCompanie);
          // close the Modal
          loaderDialog.close();
          this.closeDialog();
        }

      }
    },
    closeDialog(): void {
      //@ts-ignore
      this.dialogRef?.close();
    }
  },
  watch: {
    pass(newValue): void {
      return this.pass = newValue;
    },
  },
  mounted() {
    //@ts-ignore
    if (this.dialogRef?.data.item) {
      //@ts-ignore
      const item = this.dialogRef.data.item;
      this.id  = item.id
      this.name = item.name
      this.kdnr = item.kdnr
      this.desc = item?.desc ?? ''
      //@ts-ignore
      this.formMode = this.dialogRef.data.mode
    }
  },
  unmounted() {
    this.closeDialog();
  }
});
