import {State} from "@/store/state";
import {ActionContext, ActionTree} from "vuex";
import {emitter} from "@/store/eventBus";

import {app, dialogRef, toastRef} from "@/main";
import api from "@/api/api";
import {
    CompanyInterface,
    DeviceInterface, DoctypeInterface, FileGenerateParams,
    FileInterface,
    LoginInterface, PartsInterface,
    UploadInterface, UserLoginInterface
} from "@/interfaces/form-interfaces";
import {
    deleteInterface,
    LocalUserData,
    SetCurrentDevicePayload,
    UploadPayloadData
} from "@/interfaces/payload-interfaces";
import {AxiosPromise, AxiosResponse} from "axios";
import {ApiDataResponse, ApiErrorResponse, LoginUserApiResponse, MediaResponseBody} from "@/interfaces/api-interfaces";
import {Log} from "@/components/Logs/Log";
import {Message} from "@/components/UI/Message";
import {Companie} from "@/components/Forms/companie";
import {DynamicDialogCloseOptions, DynamicDialogInstance} from "primevue/dynamicdialogoptions";

/**
 * @description VUEX Actions to commit the changes into the state via mutations
 * @type ActionTree
 * @see https://vuex.vuejs.org/guide/actions.html
 */
export const actions: ActionTree<State, any> = {
    /**
     * @method showModal
     * @description global method to call the $modal from the Vue-Instance
     * @param {Object} context - Store Context
     * @param {Object} payload - expected a Object {name: 'name of the modal from store-array', componentProps: {}}
     */
    showModal: (context, payload): DynamicDialogInstance | null => {
        const currentModal = context.getters.modals[payload.name];
        const componentProps = payload.componentProps;

        //await app;
        if (currentModal) {
            //context.commit('setModalDialog', true)
            //const dialogInstance =
            return dialogRef.open(
                currentModal.component, {
                    /*onClose(options?: DynamicDialogCloseOptions) {
                        emitter.emit('closeDialog', dialogInstance)
                    },*/
                    props: {...currentModal.props},
                    data: {...componentProps}
                }
            );
        } else {
            return null;
        }
    },
    /**
     * @method hideModal
     * @deprecated
     * @description global method to close the $modal from the Vue-Instance
     * @param {ActionContext} _ - Store Context (not used)
     * @param {string} payload - expected a modal name as string
     */
    hideModal: async (context: ActionContext<State, any>, name: string): Promise<void> => {

        //context.commit('setModalDialog', false);
        //dialogRef.
    },
    /**
     * @method hideAllModals
     * @deprecated
     * @description global method to close all active $modals from the Vue-Instance
     */
    hideAllModals: async (): Promise<void> => {
        //await app;
        /*app.$modal.hideAll();*/
    },
    /**
     * @method setCompanies
     * @description global method to call the companies from API and set to Store
     * @param {ActionContext} context - vuex default context obejct
     * @returns {Promise<void>}
     * @throws Error
     */
    setCompanies: async (context: ActionContext<State, any>): Promise<void> => {
        try {
            const res: CompanyInterface[] | undefined = await api.companies.get({pagination: {start: 0, limit: 500}});
            if (res) {
                context.commit("setCompanies", res);

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Firmennamen vom Server geladen...`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log)
            }

        } catch (error: any) {
            if (error) {// Show error messageBox
                //toastRef.add({severity: error, life:3000, summary: 'Netzwerk Fehler', detail: `Fehler: ${error.status} - ${error.message}`})
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status.toString(),
                    message: `${JSON.stringify(error.message)}\r\nUrsprung: setCompanies() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method newCompanie
     * @description method to send one new Companie to the API and push to the companies array in the Store
     * @param {ActionContext} context - vuex default context obejct
     * @param {CompanyInterface} payload
     * @returns {Promise<void>}
     */
    newCompanie: async (context: ActionContext<State, any>, payload: CompanyInterface): Promise<void> => {
        try {
            const res: CompanyInterface | boolean = await api.companies.post(payload);
            // copy companies array from state
            if (res !== false) {
                const companies: CompanyInterface[] = [...context.getters.companies];
                // push new item into array
                companies.unshift(res);
                // commit back to state
                context.commit("setCompanies", companies);

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Neue Firma wurde hinzugefügt`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log)
                new Message('success', 'Firma wurde hinzugefügt!')
            }

        } catch (error: any) {
            if (error?.response) {// Show error messageBox
                new Message('error', `Fehler: ${error.response.data.statusCode} - ${error.response.data.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.response.data.statusCode.toString(),
                    message: `${JSON.stringify(error.response.data.message)}\r\nUrsprung: newCompanie() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method updateCompanie
     * @description method to send Companie updated data to the API and push to the companies array in the Store
     * @param {ActionContext} context - vuex default context obejct
     * @param {CompanyInterface} payload
     * @returns {Promise<void>}
     */
    updateCompanie: async (context: ActionContext<State, any>, payload: CompanyInterface) => {
        try {
            const res: CompanyInterface | boolean = await api.companies.put(payload);
            // copy companies array from state
            if (res !== false) {
                console.log(res)
                const companies: CompanyInterface[] = [...context.getters.companies];
                // find item and replace it
                companies.map((comp: CompanyInterface, index: number) => {
                    if (comp.id === res.id) {
                        companies[index] = res
                    }
                });
                // commit back to state
                context.commit("setCompanies", [...companies]);

                new Message('success', `Firma mit Kundennummer ${res.kdnr} wurde bearbeitet`)

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Firma mit Kundennummer ${res.kdnr} wurde bearbeitet`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log)
            }
        } catch (e: any) {
            if (e) {// Show error messageBox
                new Message('error', `Fehler: ${e.status} - ${e.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: e.status.toString() ?? '40x',
                    message: `${JSON.stringify(e.message)}\r\nUrsprung: updateCompanie() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method deleteCompanie
     * @description method to delete one Companie by the id to the API and push to the companies array in the Store
     * @param {ActionContext} context - vuex default context obejct
     * @param {CompanyInterface} payload
     * @returns {Promise<void>}
     */
    async deleteCompanie(context: ActionContext<State, any>, payload: deleteInterface) {
        try {
            const res = await api.companies.delete(payload.id);

            const companies: CompanyInterface[] = context.getters.companies.filter((c: any) => c.id !== payload.id)
            context.commit('setCompanies', [...companies])

            new Message('success', `Firma mit ID ${payload.id} aus der Datenbank gelöscht!`)

            await api.logs.post(new Log({
                type: 'info',
                status: 'api-call',
                message: `Firma mit ID ${payload.id} aus der Datenbank gelöscht!`
            }))


        } catch (e: any) {
            new Message('error', e.message.toString())

            await api.logs.post(new Log({
                type: 'error',
                status: e.status.toString() ?? '40x',
                message: `Fehler beim löschen der Fa. ${payload.id} aus der Datenbank!`
            }))
        }
    },
    /**
     * @method newDoctype
     * @description method to send one new Doctype Object to API and push it to Doctypes-Array
     * @param {ActionContext} context - vuex default context obejct
     * @param {DoctypeInterface} payload
     * @returns {Promise<void>}
     */
    newDoctype: async (context: ActionContext<State, any>, payload: DoctypeInterface): Promise<void> => {
        try {
            const res = await api.doctypes.post(payload);

            if (res) {
                // copy parts array from state
                const doctypes = [...context.getters.doctypes];
                // push new item into array
                doctypes.push(res);
                // commit back to state
                context.commit("setDoctypes", doctypes);

                new Message('success', 'Dokumententyp erstellt!')

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Neuer Dokumententyp wurde hinzugefügt...`,
                    user: context.getters.currentUser.id
                });
                await api.logs.post(log)
            }
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\nUrsprung: newDoctype() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method setDoctypes
     * @description call the doctypes from API and set to Store
     * @param {ActionContext} context - vuex default context object
     * @returns {Promise<void>}
     */
    setDoctypes: async (context: ActionContext<State, any>): Promise<void> => {
        try {
            const res = await api.doctypes.get({pagination: {limit: 100}});
            if (res) {

                context.commit("setDoctypes", res);

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Dokumententypen vom Server geladen...`,
                    user: context.getters.currentUser?.id ?? null
                });
                api.logs.post(log);
            }
        } catch (error: any) {
            if (error) {// Show error messageBox
                console.log(error)
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: setDoctypes() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method newPart
     * @description send a new Part Object to the API and push to the store
     * @param {ActionContext} context - vuex default context object
     * @param {PartsInterface} payload
     * @returns {Promise<void>}
     */
    newPart: async (context: ActionContext<State, any>, payload: PartsInterface): Promise<void> => {
        try {
            const res = await api.parts.post(payload);
            if (res) {

                // copy parts array from state
                const parts = [...context.getters.parts];
                // push new item into array
                parts.push(res);
                // commit back to state
                context.commit("setParts", parts);

                new Message('success', `Artikel ${res.title} erstellt!`)

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Neue Artikelnummer ${res.title} wurde hinzugefügt`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log);
            }

        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ? JSON.stringify(error.status) : '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: newPart() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method updatePart
     * @description update a Part Object to the API and push to the store
     * @param {ActionContext} context - vuex default context object
     * @param {PartsInterface} payload
     * @returns {Promise<void>}
     */
    updatePart: async (context: ActionContext<State, any>, payload: PartsInterface) => {
        try {
            const res = await api.parts.put(payload)
            if (res) {
                const parts:PartsInterface[] = [...context.getters.parts]
                const updatedParts = await Promise.all(parts.map((part:PartsInterface) => {
                    part.id === res.id ? {...part, ...res} : part;
                }));

                context.commit('setParts', [...updatedParts]);

                new Message('success', `Artikelnummer ${res.title} erfolgreich aktualisiert!`);

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Artikelnummer ${res.title} wurde aktualisiert`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log);
            }
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ? JSON.stringify(error.status) : '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: updatePart() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method deletePart
     * @description method to delete one Part by the id to the API and push to the parts array in the Store
     * @param {ActionContext} context - vuex default context obejct
     * @param {deleteInterface} payload
     * @returns {Promise<void>}
     */
    async removePart(context: ActionContext<State, any>, payload: deleteInterface) {
        try {
            const res = await api.parts.delete(payload.id);

            const parts: PartsInterface[] = context.getters.parts.filter((c: any) => c.id !== payload.id)
            context.commit('setParts', [...parts])

            new Message('success', `Artikelnummer mit ID ${payload.id} aus der Datenbank gelöscht!`)

            await api.logs.post(new Log({
                type: 'info',
                status: 'api-call',
                message: `Artikelnummer mit ID ${payload.id} aus der Datenbank gelöscht!`
            }))


        } catch (e: any) {
            new Message('error', e.message.toString())

            await api.logs.post(new Log({
                type: 'error',
                status: e.status.toString() ?? '40x',
                message: `Fehler beim löschen der Artikelnummer. ${payload.id} aus der Datenbank!`
            }))
        }
    },
    /**
     * @method setParts
     * @description call the parts from API an set to store
     * @param {ActionContext} context - vuex default context object
     * @returns {Promise<void>}
     */
    setParts: async (context: ActionContext<State, any>): Promise<void> => {
        try {
            const res = await api.parts.get({pagination: {limit: 100}});
            if (res) {
                context.commit("setParts", res);

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Artikelnummern wurden vom Server geladen...`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log);
            }

        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error?.response.data.statusCode} - ${error.response.data.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: setParts() action`
                });
                await api.logs.post(log)
            }
        }
    },

    /**
     * @method newUploads
     * @description upload one or more file uploads
     * @param {ActionContext} context
     * @param {UploadPayloadData} payload
     *
     * @return {FileInterface[]}
     * @throws {Error}
     */
    newUploads: async (context: ActionContext<State, any>, payload: UploadPayloadData): Promise<FileInterface[] | undefined> => {
        try {
            context.commit('setUploadStatus', true);
            // await the upload of all files
            const uploads: UploadInterface[] = await api.upload.post(payload.formEl);

            // loop through the uploaded data
            const files = await Promise.all(uploads.map(async (upload: UploadInterface) => {
                const file = {
                    filename: upload.name,
                    doctype: [payload.doctypeId],
                    url: [upload.id]
                }
                // call newFile method
                const fileRes: FileInterface = await context.dispatch('newFile', file);

                return fileRes;
            }));
            context.commit('setUploadStatus', false);
            const log = new Log({
                type: 'info',
                status: 'api-call',
                message: `${files.length.toString()} Datei(en) wurden hochgeladen`,
                user: context.getters.currentUser.id
            })
            api.logs.post(log);
            return files;
        } catch (error: any) {
            if (error) {// Show error messageBox

                context.commit('setUploadStatus', false);
                // Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message} Hochladen fehlschlagen`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: newUploads() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method newFile
     * @description save new File to api
     *
     * @param context
     * @param payload
     * @return {AxiosResponse<FileInterface>}
     */
    newFile: async (context: ActionContext<State, any>, payload: FileInterface): Promise<FileInterface | undefined> => {
        try {
            // send new File (Media) to API
            const fileRes: FileInterface | undefined = await api.files.post(payload);

            // return the object
            if (fileRes) {
                new Message('success', 'Datei(en) hochgeladen!')
                return fileRes
            }
            
        } catch (error: any) {
            if (error) {// Show error messageBox

                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: newFile() action`
                });
                await api.logs.post(log)
            }
        }
    },

    /**
     * @method romeveFile
     * @description remove a file from server
     *
     * @param {ActionContext} context
     * @param {string} payload
     * @return {void}
     */
    removeFile: async (context: ActionContext<State, any>, payload: FileInterface): Promise<void> => {
        try {
            if (confirm('Sind sie sicher?')) {
                // send the id of file to remove from server
                const res = await api.files.delete(payload);
                const oldFiles: FileInterface[] = context.getters.files;
                const newFiles: FileInterface[] = oldFiles.filter(file => file.id !== res.data.id)

                context.dispatch("setFiles", newFiles);

                new Message('success', 'Datei erfolgreich gelöscht!')

                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Datei ${res.data.data.attributes.filename} wurde vom Server gelöscht`,
                    user: context.getters.currentUser.id
                });
                api.logs.post(log)
            }
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: removeFile() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     *
     * @param context
     * @param {FileGenerateParams} payload
     */
    generateFiles: async (context: ActionContext<State, any>, payload: FileGenerateParams): Promise<FileInterface | any> => {
        try {
            if(payload.docs.length) {
                console.log('inAction', {...payload})
                const res = await api.files.genarate(payload)
                if (res) {
                    // load devices from server
                    context.dispatch('setDevices');
                    // generate message
                    new Message("success", "Dateien werden generiert und werden gleich per Mail zugestellt.")
                    // create log
                    const log = new Log({
                        type: 'info',
                        status: '200',
                        message: 'Dateien werden generiert und versendet!',
                        user: context.getters.currentUser.id
                    })

                    await api.logs.post(log)
                }
            }
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error?.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error?.status ?? '40x',
                    message: `${JSON.stringify(error?.message)}\r\n Ursprung: generateFile() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method setFiles
     * @description get Files Array from API and sets to state (needed only on the files page and files modal)
     *
     * @param {ActionContext} context - vuex default context object
     * @returns {void}
     */
    setFiles: async (context: ActionContext<State, any>): Promise<void> => {
        try {
            const res = await api.files.get({pagination: {limit: 200}});
            if (res) {
                context.commit("setFiles", res);
                const log = new Log({
                    type: 'info',
                    status: 'api-call',
                    message: `Dateien vom Server geladen`,
                    user: context.getters.currentUser.id
                });
                await api.logs.post(log)
            }
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.response.data.message)}\r\nUrsprung: setFiles() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method newDevice
     * @description send a new Device Object to API and push into devices array in the store
     * @param {ActionContext} context - vuex default context object
     * @param {DeviceInterface} payload
     *
     * @returns {Promise<void>}
     */
    newDevice: async (context: ActionContext<State, any>, payload: DeviceInterface): Promise<DeviceInterface | undefined> => {
        try {
            // send data to API
            const res = await api.devices.post(payload);

            // push device to devices array (this.pushDevice)
            context.dispatch("pushDevice", res);

            new Message('success', `Neues Gerät ${payload.sn} auf dem Server gespeichert`);

            const log = new Log({
                type: 'info',
                status: 'api-call',
                message: `Neues Gerät ${payload.sn} auf dem Server gespeichert`,
                sn: payload.sn,
                user: context.getters.currentUser.id
            });
            api.logs.post(log);
            return res;
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status,
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: newDevice() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method updateDevice
     * @description update an device object to API the changes in the store reflects through currentDevice state
     * @param {ActionContext} context - vuex default context object
     *
     * @param {DeviceInterface} payload - expected an object
     * @returns {Promise<void>}
     */
    updateDevice: async (context: ActionContext<State, any>, payload: DeviceInterface): Promise<void> => {
        try {
            // send data to API
            const res: DeviceInterface | undefined = await api.devices.put(payload);
            // filter the device in the devices array

            new Message('success', `Gerät ${payload.sn} auf dem Server aktualisiert`);

            // Log Eintrag
            const log = new Log({
                type: 'info',
                status: 'api-call',
                message: `Gerät ${payload.sn} auf dem Server aktualisiert`,
                sn: payload.sn,
                user: context.getters.currentUser.id
            });
            api.logs.post(log);
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status,
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: updateDevice() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method removeDevice
     * @description remove one device on server with the given deviceId
     * @param {ActionContext} context - vuex default context object
     * @param {string} payload - the deviceId to delete from server
     * @returns {Promise<void>}
     */
    removeDevice: async (context: ActionContext<State, any>, payload: string): Promise<void> => {
        try {
            // send id to API
            const res = await api.devices.delete(payload);
            // remove from devices array
            // delete device in the devices array
            context.dispatch("spliceDevice", res);

            new Message('success', `Gerät ${res.sn} vom Server gelöscht`);

            // Log Eintrag
            const log = new Log({
                type: 'info',
                status: 'api-call',
                message: `Gerät ${res.sn} vom Server gelöscht`,
                sn: res.sn,
                user: context.getters.currentUser.id
            });
            api.logs.post(log);
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status,
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: removeDevice() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method setDevices
     * @description call the devices from API and set into the store
     * @param {ActionContext} context
     * @returns {Promise<void>}
     */
    setDevices: async (context: ActionContext<State, any>): Promise<void> => {
        try {
            const res = await api.devices.get({pagination: {limit: 100}});

            context.commit("setDevices", res);
            // Log Eintrag
            const log = new Log({
                type: 'info',
                status: 'api-call',
                message: `Geräte vom Server geladen`,
                user: context.getters.currentUser.id
            });
            api.logs.post(log);
        } catch (error: any) {
            console.log(error)
            if (error) {// Show error messageBox
                new Message('error', `Fehler: ${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: error.status ?? '40x',
                    message: `${JSON.stringify(error.message)}\r\n Ursprung: setDevices() action`
                });
                await api.logs.post(log)
            }
        }
    },
    /**
     * @method loginCustomer
     * @description send the customer user login data to API
     * @param {ActionContext} context - vuex default context object
     * @param {LoginInterface} payload - expected keys "sn":String && "pass":String
     * @returns {Promise<boolean>}
     */
    loginCustomer: async (context: ActionContext<State, any>, payload: LoginInterface): Promise<boolean | undefined> => {
        try {
            const res = await api.deviceLogin({
                sn: payload.sn,
                pass: payload.pass
            });

            let companieName = '';
            let sn = '';

            // prüfen ob object oder array // evtl. am server parsen
            if (Array.isArray(res)) {
                companieName = res[0].companie.name
                sn = res[0].sn
                context.commit('setCurrentDevice', res);
            }

            const log = new Log({
                status: '200',
                type: 'customer',
                message: `Ein Nutzer der Firma ${companieName} hat sich eingelogt!`,
                companie: companieName,
                sn: sn
            })
            await api.logs.post(log);

            return true;
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `${error.status} - ${error.message}`)
                // Log Eintrag
                const log = new Log({
                    sn: payload.sn,
                    status: error.status.toString() ?? '40x',
                    type: 'error',
                    message: `${error.message}\r\n Herkunft: loginCustomer() action`
                })

                await api.logs.post(log);

                return false
            }
        }
    },
    /**
     * @method loginUser
     * @description send the login user data from a admin user to API
     * @param {ActionContext} context - vuex default context object
     * @param {LoginInterface} payload - expected keys "username": String, "password": String
     * @returns {Promise<boolean>} - JWT Token || Server-Error
     */
    loginUser: async (context: ActionContext<State, any>, payload: LoginInterface): Promise<boolean | undefined> => {
        try {
            const res = await api.userLogin({
                identifier: payload.username,
                password: payload.pass
            });

            if (res.data.user) {
                context.commit("setCurrentUser", res.data.user);
                context.commit("setAuthenticated", true);
            }
            if (res.data.jwt) {
                // Add a Auth token to default headers
                context.commit("setAuthKey", res.data.jwt);
            }
            localStorage.setItem(
                "pw_userinfo",
                JSON.stringify({
                    currentUser: res.data.user,
                    isAuthenticated: true,
                    authKey: res.data.jwt
                })
            );
            new Message('success', 'Sie wurden erfolgreich eingeloggt!')
            // Log Eintrag
            const log = new Log({
                type: 'info',
                status: res.status.toString(),
                message: `Der Bearbeiter mit der Email ${res.data.user.email} wurde erfolgreich eingelogt!`,
                user: res.data.user.id
            });

            await api.logs.post(log);
            return true;
        } catch (error: any) {
            if (error) {// Show error messageBox
                new Message('error', `${error.message} - Login derzeit nicht möglich`)
                // Log Eintrag
                const log: Log = new Log({
                    type: 'error',
                    status: JSON.stringify(error.status),
                    message: JSON.stringify(error.message),
                    companie: null,
                    user: null
                });

                await api.logs.post(log);
                return false;
            }
        }
    },
    /**
     * @method checkAuthStatus
     * @description check if the 'pw_userinfo' object available in the localStorage
     * @param {ActionContext} context
     * @return {Promise<boolean>}
     */
    checkAuthStatus: async (context: ActionContext<State, State>): Promise<boolean> => {
        // Get Userdata from localstorage

        const localUserData: LocalUserData = JSON.parse(<string>localStorage.getItem("pw_userinfo"));
        if (localUserData) {
            context.commit("setCurrentUser", localUserData.currentUser);
            context.commit("setAuthenticated", localUserData.isAuthenticated);
            context.commit("setAuthKey", localUserData.authKey);
            return true;
        } else {
            return false;
        }
    },
    /**
     * @method logoutUser
     * @description logout the admin user, remove the AuthKey (JWT Token) and clear the localStorage
     * @param {ActionContext} context - vuex default context object
     * @returns {Promise<boolean | undefined>} - redirect to login view or Error
     * @throws Error
     */
    logoutUser: async (context: ActionContext<State, any>): Promise<boolean | undefined> => {
        try {
            const user = context.getters.currentUser;
            // remove localstorage item
            if (user) {
                localStorage.removeItem("pw_userinfo");
                new Message('success', 'Sie wurden erfolgreich ausgeloggt!')
                // Log Eintrag
                const log = new Log({
                    type: 'info',
                    status: '200',
                    message: `User ${user.email} wurde erfolgreich ausgeloggt!`,
                    user: user.id
                });

                await api.logs.post(log);
            }
            // remove auth headers
            await api.removeAuthHeader();
            // set the state
            await context.commit("setCurrentUser", null);
            await context.commit("setAuthKey", "");
            await context.commit("setAuthenticated", false);

            //app.$router.push('admin-login');

            return true;
        } catch (error: any) {
            if (error) {// Show MessageBox
                new Message('error', `Fehler: Logout fehlgeschlagen!`)
                // Log Eintrag
                const log = new Log({
                    type: 'error',
                    status: '40x',
                    message: 'Logout fehlgeschlagen!'
                })

                api.logs.post(log);
                app.$router.push('admin-login')
            }
        }
    },
    /**
     * @method pushDevice
     * @description method to push one device object to the devices-array in the store
     * @param {ActionContext} context - vuex default context object
     * @param {DeviceInterface} payload - device object
     * @return {void}
     */
    pushDevice: (context: ActionContext<State, any>, payload: DeviceInterface): void => {
        // payload is the device object from server
        const devices = [...context.getters.devices];
        // push to beginning of array
        devices.unshift(payload);
        context.commit("setDevices", devices);
    },
    /**
     * @method spliceDevice
     * @description remove one device object from the devices-array in the store
     * @param {ActionContext} context - vuex default context object
     * @param {DeviceInterface} payload - is the device object from server that should be removed
     * @return {void}
     */
    spliceDevice: (context: ActionContext<State, any>, payload: DeviceInterface): void => {
        // payload is the device object from server
        const devices: DeviceInterface[] = [...context.getters.devices];
        const filteredDevices = devices.filter((dev: DeviceInterface) => dev.id !== payload.id);
        context.commit("setDevices", filteredDevices);
    },
    /**
     * @method setCurrentDevice
     * @description find the device with the given ID in the devices array and set the state.currentDevice
     * @param {SetCurrentDevicePayload} payload given {deviceId: x} of one device object or completed device-object from devices array
     * @return {void}
     */
    setCurrentDevice: (context: ActionContext<State, any>, payload: SetCurrentDevicePayload = {}): void => {
        let device;
        if (payload && payload["deviceId"]) {
            device = context.getters.devices.find((dev: DeviceInterface) => dev.id === payload.deviceId);
        } else {
            device = payload;
        }
        context.commit("setCurrentDevice", device);
    },
    /**
     * @method setModals
     * @description set modals object to the state
     *
     * @param {ActionContext} context
     * @param payload
     * @return {void}
     */
    setModals: (context: ActionContext<State, any>, payload: any): void => context.commit("setModals", payload),
    /**
     * @method genPass
     * @description function to generate a new password
     *
     * @param {ActionContext} _
     * @param {string} payload
     *
     * @return {number}
     */
    genPass: (_: ActionContext<State, any>, payload: string): number => {
        /*let hash = 0;

        if (payload === "") return hash;

        for (let i = 0; i < payload.length; i++) {
            const char = payload.charCodeAt(i);
            hash = (hash << 3) - hash + char;
            hash = hash & hash;
        }*/

        const now = new Date().getTime().toString();

        return Number(now.slice(now.length - 6));
    }
};
