import {CompanyInterface, DeviceInterface, FileInterface, PartsInterface} from "@/interfaces/form-interfaces";

export class Device {
    id?: string;
    sn: string;
    companie: CompanyInterface;
    part: PartsInterface;
    files: FileInterface[];
    pass?: string;
    updatedAt?: string;

    constructor(props: DeviceInterface) {
        this.id = props.id;
        this.sn = props.sn;
        this.companie = props.companie;
        this.part = props.part;
        this.files = props.files;
        this.pass = props.pass;
        this.updatedAt = props.updatedAt;
    }
}
