
import {defineComponent} from "vue";

import Button from "primevue/button";

export default defineComponent({
  name: 'EditBtn',
  components: {Button},
  data() {
    return {};
  },
});
