
import {defineComponent} from "vue";
import Button from "primevue/button";


export default defineComponent({
  name: 'DeleteBtn',
  components:{Button},
  data() {
    return {};
  },
  methods: {},
});
