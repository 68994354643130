
import {defineComponent} from "vue";

import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/store/eventBus";

import {FilterMatchMode} from "primevue/api";
import Button from "primevue/button";
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import DeleteBtn from "@/components/UI/DeleteBtn.vue";
import {DoctypeInterface, FileInterface} from "@/interfaces/form-interfaces";

/**
 * @description FileList - represent the Files Array Component in action cards
 *
 * @vue-prop {Array<FileItem>} [items=[]] - atterate through an array of FileItem-Objects
 * @vue-prop {Boolean} [isAdmin=false] - this prop pass through to the FileItem, to enable an administrative area to handle the items (edit,delete)
 * @vue-prop {Boolean} [selectable=false] - this prop pass through to the FileItem, to enable a checkbox to make item selectable
 */
export default defineComponent({
  name: 'FileList',
  components: {Button, Card, Column, DataTable, DeleteBtn, InputText},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isAdmin: {type: Boolean, default: false},
    selectable: {type: Boolean, default: false},
    searchable: {type: Boolean, default: true}
  },
  data() {
    return {
      filters: {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      selectedFiles: <FileInterface[]>[]
    }
  },
  computed: {
    ...mapGetters(['doctypes']),
    headers(): Array<any> {
      return [
        {
          text: 'Hochgeladen am',
          align: 'start',
          value: 'createdAt',
        },
        {text: 'Datei-Typ', value: 'doctype'},
        {text: 'Datei-Name', value: 'filename'},
        {text: 'Actions', sortable: false, value: 'download'},
      ]
    }
  },
  methods: {
    ...mapActions(['setDoctypes']),
    /**
     * @vue-method doctype
     * @description filter a doctype object from doctypes array with a given id
     * @param {String} id
     * @return {String}
     */
    doctype(id: string | any): string {
      const dt: DoctypeInterface[] = this.doctypes.filter((item: DoctypeInterface) => item.id === id || item.id === id.id);
      return dt[0].title
    },
    /**
     * @vue-method filelink
     * @description generate a download url to a file
     * @param {String} url
     * @return {String}
     */
    filelink(url: string): string {
      return process.env.VUE_APP_API_URL + url;
    },
    /**
     * @vue-method onDelete
     * @description emits the onDeleteBtn method on EventBus
     * @return {void} - EventBus $emit
     */
    onDelete(file: FileInterface): void {
      EventBus.onDeleteBtn({fileId: file})
    },
  },
  watch: {
    selectedFiles(newValue: FileInterface[]) {
      // new Event to EventBus
      EventBus.onSelectFiles(newValue);
    }
  },
  created() {
    this.setDoctypes();
  }
});
