import {DoctypeInterface} from "@/interfaces/form-interfaces";

export class Doctype {
    id?: string;
    title: string;
    desc?: string;

    constructor(props: DoctypeInterface) {
        this.id = props.id;
        this.title = props.title;
        this.desc = props.desc;
    }
}
