import {State} from "@/store/state";
import {MutationTree} from "vuex";

import api from "@/api/api";

export const mutations: MutationTree<State> = {
    setAuthKey: (state: State, payload: string) => {
        state.authKey = payload;
        api.setAuthHeader(payload);
    },
    setCurrentUser: (state, payload) => (state.currentUser = payload),
    setAuthenticated: (state, payload) => (state.isAuthenticated = payload),
    setUploadStatus: (state, payload) => (state.uploadStatus = payload),
    setCompanies: (state, payload) => (state.companies = payload),
    setDevices: (state, payload) => (state.devices = payload),
    setFiles: (state, payload) => (state.files = payload),
    setDoctypes: (state, payload) => (state.doctypes = payload),
    setParts: (state, payload) => (state.parts = payload),
    setCurrentDevice: (state, payload) => (state.currentDevice = payload),
    setModalDialog: (state, payload) => (state.isModalDialog = payload),
    setModals: (state, payload) => (state.modals = payload)
};
