
import {defineComponent} from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

import {
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccount,
  mdiLaptop,
} from '@mdi/js';
import {Message} from "@/components/UI/Message";

/**
 * @description LoginForm - LoginForm Component is the user input area to login to the app
 *
 * @vue-prop {String} [mode='customer'] - the login-form is usable for customer and the admin
 * @vue-prop {String} [sn=null] - the serial-number can pass through as a url param to the form
 * @vue-event {Object} submit-form - emit a submit-form event by sending the form
 * @vue-event forgot-pass - emit the event by click on forgot-password link
 * @vue-data {Object} [icons={eye: mdiEyeOutline, user: mdiAccount, device: mdiLaptopWindows,}]
 * @vue-data {String | null } [username=this.sn] - v-model for the username input element
 * @vue-data {String} pass - v-model for the password input element
 * @vue-data {Boolean} passIsVisible - make the password input readable
 */
export default defineComponent({
  name: 'LoginForm',
  components: {
    Button,
    InputText
  },
  props: {
    mode: {
      type: String,
      default: 'customer',
    },
    sn: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        eye: mdiEyeOutline,
        user: mdiAccount,
        device: mdiLaptop,
      },
      username: <string | null>this.sn,
      pass: <string>'',
      passIsVisible: false,
    };
  },
  computed: {
    getUsername(): string | null {
      return this.sn ? this.sn : this.username
    },
    emailSubject(): string {
      return `DeviceApp® Passwort vergessen für ${this.getUsername}`
    },
    userLabel(): string {
      return this.mode === 'customer' ? 'Kundennummer / Gerätenummer' : 'Username';
    },
    passType(): string {
      return !this.passIsVisible ? 'password' : 'text';
    },
    activeUserIcon(): string {
      return this.mode === 'customer' ? 'mdi mdi-laptop' : 'mdi mdi-account';
    },
    activePassIcon(): string {
      return !this.passIsVisible ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline';
    },
  },
  methods: {
    /**
     * @vue-method showPass
     * @description method to make the password field readable
     * @returns {void}
     */
    showPass(): void {
      //console.log('shoPass fired');
      this.passIsVisible = !this.passIsVisible;
    },
    /**
     * @vue-method sendForm
     * @description method to validate and submit the user input
     * @returns {$emit} 'submit-form'-event
     */
    sendForm(): boolean {
      // emits the form data
      if (!this.getUsername || !this.pass.trim()) {
        // Show error message
        new Message('warn', 'Bitte füllen Sie alle Felder aus!')
        console.log("Empty form can't be submited");
        return false;
      } else {
        this.$emit('submit-form', {username: this.getUsername, pass: this.pass});
        console.log('emit ausgeführt')
        //reset des Formulars
        this.username = null;
        this.pass = '';
        return true;
      }
    },
    /**
     * @vue-method forgotPass
     * @description fire the 'forgot-pass' Event with the given username
     * @returns {$emit} 'forgot-pass'- Event
     */
    forgotPass(): void {
      this.$emit('forgot-pass', {username: this.username})
    }
  },
  watch: {},
});
