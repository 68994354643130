import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    Router, RouteRecord,
    RouterOptions
} from 'vue-router';
import CustomerLogin from '../pages/CustomerLogin.vue';
import CustomerView from '../pages/CustomerView.vue';

import store from "@/store";

/**
 * @description Array for the Routes
 *
 * @type {Object[]}
 */
export const routes: any[] = [
    {
        path: '/',
        redirect: '/customer-login'
    },
    {
        path: '/customer-login',
        name: 'customer-login',
        component: CustomerLogin,
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (store.getters.isAuthenticated === true) next({name: 'user'})
            else next()
        }
    },
    {
        path: '/customer-view',
        name: 'customer-view',
        component: CustomerView,
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (!store.getters.currentDevice.length)
                next({path: '/'});
            else
                next();
        }
    },
    {
        path: '/admin-login',
        name: 'admin-login',
        component: () => import(/* webpackChunkName: "admin" */ "../pages/AdminLogin.vue"),
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (store.getters.isAuthenticated === true) next({name: 'user'})
            else next()
        }
    },
    {
        path: '/devices',
        name: 'devices',
        component: () => import(/* webpackChunkName: "devices" */ "../pages/Devices.vue"),
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (!store.getters.isAuthenticated) next({name: 'admin-login'})
            else next()
        }
    },
    {
        path: '/files',
        name: 'files',
        component: () => import(/* webpackChunkName: "files" */ "../pages/Files.vue"),
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (!store.getters.isAuthenticated) next({name: 'admin-login'})
            else next()
        }
    },
    {
        path: '/companies',
        name: 'companies',
        component: () => import(/* webpackChunkName: "companies" */ "../pages/Companies.vue"),
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (!store.getters.isAuthenticated) next({name: 'admin-login'})
            else next()
        }
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import(/* webpackChunkName: "logs" */ "../pages/Logs.vue"),
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (!store.getters.isAuthenticated) next({name: 'admin-login'})
            else next()
        }
    },
    {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ "../pages/User.vue"),
        beforeEnter: (to: RouteRecord, from: RouteRecord, next: NavigationGuardNext) => {
            if (!store.getters.isAuthenticated) next({name: 'admin-login'})
            else next()
        }
    },
]

/**
 * @description VueRouter Instance
 *
 * @type {Router}
 */
const router: Router = createRouter(<RouterOptions>{
    history: createWebHistory(),
    routes
});

/**
 * Dieser Code definiert eine Router-Middleware-Funktion in TypeScript, die vor jedem Router-Navigationsschritt ausgeführt wird.
 * Es überprüft den Authentifizierungsstatus und führt die entsprechende Aktion im Store aus, bevor es den nächsten Schritt ermöglicht.
 */
router.beforeEach(async (to: any, from: any, next: NavigationGuardNext) => {
    await store.dispatch('checkAuthStatus');
    next()
})

export default router
