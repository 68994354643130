/*import store from "@/store";
import {Store} from "vuex";
import {State} from "@/store/state";*/
import {toastRef} from "@/main";

export class Message {
    private _type: "success" | "info" | "warn" | "error" | undefined;
    private _message: string;
    private _showModal = (): void => {
        toastRef.add({severity: this._type, detail: this._message, life: 3000})
    }

    constructor(type: "success" | "info" | "warn" | "error" | undefined, message: string) {
        this._type = type;
        this._message = message;

        this._showModal();
    }

    get type(): "success" | "info" | "warn" | "error" | undefined {
        return this._type;
    }

    get message(): string {
        return this._message;
    }
}
